<template>
  <div>
    <WsOrder
      :orderLayerFields="$store.state.stone_model[_modelName].orderLayerFields"
      :getUrl="_getUrl"
      :patchUrl="_patchUrl"
      :labelKey="_labelKey"
    ></WsOrder>
  </div>
</template>

<script>
export default {
  computed: {
    _crudSetting() {
      return this.$store.state.stone_model[this._modelName].crudSetting;
    },
    _settingOrder() {
      if (!this._crudSetting) {
        return;
      } else {
        return this._crudSetting.order;
      }
    },
    _labelKey() {
      if (!this._settingOrder) {
        return;
      }
      return this._settingOrder.labelKey;
    },
    _getUrl() {
      if (!this._settingOrder) {
        return `/${this._urlModelName}/order`;
      }
      if (this._settingOrder.getUrl) {
        return this._settingOrder.getUrl;
      } else {
        return `/${this._urlModelName}/order`;
      }
    },
    _patchUrl() {
      if (!this._settingOrder) {
        return `/${this._urlModelName}/order`;
      }
      if (this._settingOrder.patchUrl) {
        return this._settingOrder.patchUrl;
      } else {
        return `/${this._urlModelName}/order`;
      }
    },
    _modelName() {
      return this.$route.meta.modelName;
    },
    _urlModelName() {
      return this.$route.meta.urlModelName
        ? this.$route.meta.urlModelName
        : this._modelName;
    },
  },
  mounted() { },
};
</script>